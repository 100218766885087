import { BaseDomain } from "@/core/base/BaseDomain";

export class FileUploadModel extends BaseDomain {
  data: File | any;
  id?: number;
  name?: string;
  alternativeText?: string;
  caption?: string;
  formats?: string;
  hash?: string;
  ext?: string;
  mime?: string;
  size?: number;
  url?: string;
  previewUrl?: string;
  provider?: string;
  folderPath?: string;

  constructor(
    file: File,
    id?: number,
    name?: string,
    alternativeText?: string,
    caption?: string,
    formats?: string,
    hash?: string,
    ext?: string,
    mime?: string,
    size?: number,
    url?: string,
    previewUrl?: string,
    provider?: string,
    folderPath?: string
  ) {
    super();
    // this.data = new FormData()
    // this.data.append("files", data);
    this.data = file;
    this.id = id;
    this.name = name;
    this.alternativeText = alternativeText;
    this.caption = caption;
    this.formats = formats;
    this.hash = hash;
    this.ext = ext;
    this.mime = mime;
    this.hash = hash;
    this.size = size;
    this.url = url;
    this.previewUrl = previewUrl;
    this.provider = provider;
    this.folderPath = folderPath;
  }
}
