export default class MediaRecorderService {
  videoRecorder: MediaRecorder | null = null;
  audioRecorder: MediaRecorder | null = null;
  audioChunks: Blob[] = [];
  videoChunks: Blob[] = [];

  constructor() {
    this.videoRecorder = null;
    this.audioRecorder = null;
    this.audioChunks = [];
    this.videoChunks = [];
  }

  startVideoRecording(videoElement) {
    // Video elementinden kaynak objesini al
    const stream = videoElement.srcObject;

    if (stream) {
      // navigator.mediaDevices.getUserMedia({ video: true }).then((stream) => {
      this.videoRecorder = new MediaRecorder(stream);

      this.videoRecorder.ondataavailable = (event) => {
        if (event.data && event.data.size > 0) {
          this.videoChunks.push(event.data);
        }
      };

      this.videoRecorder.onstop = () => {
        const videoBlob = new Blob(this.videoChunks, { type: "video/webm" });
        const audioBlob = new Blob(this.audioChunks, { type: "audio/webm" });
        
        const blobs = {
          video: videoBlob,
          audio: audioBlob,
        };

        return blobs;
      };

      this.videoRecorder.start();
    // });
    }
  }

  startAudioRecording() {
    navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
      this.audioRecorder = new MediaRecorder(stream);
      this.audioRecorder.ondataavailable = (event) => {
        if (event.data && event.data.size > 0) {
          this.audioChunks.push(event.data);
        }
      };
      this.audioRecorder.start();
    });
  }

  stopAudioRecording() {
    if (this.audioRecorder) {
      this.audioRecorder.stop();
    }
  }

  stopVideoRecording() {
    if (this.videoRecorder) {
      this.videoRecorder.stop();
    }
  }
}
