<template>
  <div class="d-flex justify-content-end mb-10">
    <!--begin::Wrapper-->
<div class="d-flex flex-column align-items-end">
  <!--begin::User-->
  <div class="d-flex align-items-center mb-2">
    <!--begin::Details-->
    <div class="me-3">
      <span class="text-muted fs-7 mb-1">{{ time }}</span>
      <a class="fs-5 fw-bolder text-gray-900 text-hover-primary ms-1">Katılımcı</a>
    </div>
    <!--end::Details-->
    <div v-if="image" class="symbol symbol-35px symbol-circle">
      <img :src="image" />
    </div>
  </div>
  <!--end::User-->

  <!--begin::Text-->
  <div class="p-5 rounded bg-light-primary text-dark fw-bold mw-lg-1000px text-end" data-kt-element="message-text">
    {{ text }}
  </div>
  <!--end::Text-->
<template v-if="emotionAnalysis">
  <div class="d-flex flex-wrap mt-3 justify-content-end">
    <div class="d-flex flex-row">
      <div class="d-flex align-items-center w-75px w-sm-200px flex-column mt-3">
        <div class="d-flex justify-content-between w-75 mt-auto mb-2">
          <span class="fw-bold fs-7 text-gray-400">Sinirli</span>
          <span class="fw-bolder fs-7">{{emotionAnalysis.data.attributes.angry}}%</span>
        </div>
        <div class="h-5px mx-3 w-75 bg-light mb-3">
          <div class="bg-success rounded h-5px" role="progressbar" :style="{ width: emotionAnalysis.data.attributes.angry + '%' }"
          :aria-valuenow="emotionAnalysis.data.attributes.angry" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
      </div>
      <div class="d-flex align-items-center w-75px w-sm-200px flex-column mt-3">
        <div class="d-flex justify-content-between w-75 mt-auto mb-2">
          <span class="fw-bold fs-7 text-gray-400">İğrenme</span>
          <span class="fw-bolder fs-7">{{emotionAnalysis.data.attributes.disgust}}%</span>
        </div>
        <div class="h-5px mx-3 w-75 bg-light mb-3">
          <div class="bg-success rounded h-5px" role="progressbar" :style="{ width: emotionAnalysis.data.attributes.disgust + '%' }"
          :aria-valuenow="emotionAnalysis.data.attributes.disgust" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
      </div>
    </div>
    <div class="d-flex flex-row">
      <div class="d-flex align-items-center w-75px w-sm-200px flex-column mt-3">
        <div class="d-flex justify-content-between w-75 mt-auto mb-2">
          <span class="fw-bold fs-7 text-gray-400">Korku</span>
          <span class="fw-bolder fs-7">{{emotionAnalysis.data.attributes.fear}}%</span>
        </div>
        <div class="h-5px mx-3 w-75 bg-light mb-3">
          <div class="bg-success rounded h-5px" role="progressbar" :style="{ width: emotionAnalysis.data.attributes.fear + '%' }"
          :aria-valuenow="emotionAnalysis.data.attributes.fear" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
      </div>
      <div class="d-flex align-items-center w-75px w-sm-200px flex-column mt-3">
        <div class="d-flex justify-content-between w-75 mt-auto mb-2">
          <span class="fw-bold fs-7 text-gray-400">Mutlu</span>
          <span class="fw-bolder fs-7">{{emotionAnalysis.data.attributes.happy}}%</span>
        </div>
        <div class="h-5px mx-3 w-75 bg-light mb-3">
          <div class="bg-success rounded h-5px" role="progressbar" :style="{ width: emotionAnalysis.data.attributes.happy + '%' }"
          :aria-valuenow="emotionAnalysis.data.attributes.happy" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
      </div>
    </div>
    <div class="d-flex flex-row">
      <div class="d-flex align-items-center w-75px w-sm-200px flex-column mt-3">
        <div class="d-flex justify-content-between w-75 mt-auto mb-2">
          <span class="fw-bold fs-7 text-gray-400">Üzgün</span>
          <span class="fw-bolder fs-7">{{emotionAnalysis.data.attributes.sad}}%</span>
        </div>
        <div class="h-5px mx-3 w-75 bg-light mb-3">
          <div class="bg-success rounded h-5px" role="progressbar" :style="{ width: emotionAnalysis.data.attributes.sad + '%' }"
          :aria-valuenow="emotionAnalysis.data.attributes.sad" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
      </div>
      <div class="d-flex align-items-center w-75px w-sm-200px flex-column mt-3">
        <div class="d-flex justify-content-between w-75 mt-auto mb-2">
          <span class="fw-bold fs-7 text-gray-400">Şaşırma</span>
          <span class="fw-bolder fs-7">{{emotionAnalysis.data.attributes.surprise}}%</span>
        </div>
        <div class="h-5px mx-3 w-75 bg-light mb-3">
          <div class="bg-success rounded h-5px" role="progressbar" :style="{ width: emotionAnalysis.data.attributes.surprise + '%' }"
          :aria-valuenow="emotionAnalysis.data.attributes.surprise" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
      </div>
    </div>
    <div class="d-flex flex-row">
      <div class="d-flex align-items-center w-75px w-sm-200px flex-column mt-3">
        <div class="d-flex justify-content-between w-75 mt-auto mb-2">
          <span class="fw-bold fs-7 text-gray-400">Nötr</span>
          <span class="fw-bolder fs-7">{{emotionAnalysis.data.attributes.neutral}}%</span>
        </div>
        <div class="h-5px mx-3 w-75 bg-light mb-3">
          <div class="bg-success rounded h-5px" role="progressbar" :style="{ width: emotionAnalysis.data.attributes.neutral + '%' }"
          :aria-valuenow="emotionAnalysis.data.attributes.neutral" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
      </div>
    </div>
  </div>
</template>
</div>

    <!--end::Wrapper-->
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "message-out",
  props: {
    name: String,
    image: String,
    time: String,
    text: String,
    emotionAnalysis: Object,
  },
});
</script>
