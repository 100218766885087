import { ChatLogResponseAttributes } from "@/domain/pollster/model/chatLog/ChatLogResponseAttributes";

export function MessageFlow(
    text: string | any,
    speakSecond: number,
    messagesRef: ChatLogResponseAttributes[],
    lastMessageIndex: number
  ) {
    console.log(speakSecond);
    let count = 0;
    let artis = text.length / (speakSecond * 10);
    let interval = setInterval(() => {
      if (count >= text.length || !messagesRef.length)
        clearInterval(interval);

      if (lastMessageIndex >= 0 && lastMessageIndex < messagesRef.length) {
        messagesRef[lastMessageIndex].text = text.slice(0, count);
      }
      count += artis;
    }, 100);
  }